.pulse {
  position: relative;
  width: 2vh;
  height: 2vh;
  background: white;
  border-radius: 50%;
}
.pulse span {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 50%;
  display: inline-block;
  width: 100%;
  height: 100%;
  animation: animate 2.5s linear infinite;
  animation-delay: calc(-0.5s * var(--i));
}
@keyframes animate {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  90% {
    transform: scale(3);
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}