.nav{
  position: fixed;
  height:10vh;
  width:100%;
  top:0;
  left:0;
  right:0;
  background: rgba(21,22,22, 0.5);
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  a{
    font-family: 'Orbitron', sans-serif;
    color:white;
    text-decoration: none;

  }
  a:hover{
    color: rgba(256, 256, 256, 0.7);
  }
}